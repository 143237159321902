import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Container } from '../components/container'
import { Layout } from '../components/layout'
import messageWriting from '../assets/images/message_writing.svg'
import mail from '../assets/images/mail.svg'
import { Trans, useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { ContactFormValues } from '../common/contact-form.data'
import { useState } from 'react'
import { Feedback } from '../components/feedback'
import { Input } from '../components/input'

const ContactPage: FC = () => {
  const { t } = useTranslation('contact')

  const methods = useForm()
  const { handleSubmit } = methods

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<boolean>()

  const submitForm = useCallback(async (values: ContactFormValues) => {
    setLoading(true)

    const response = await fetch('/php/submit-contact.php', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
    const result = await response.json()

    setSuccess(result.success)

    setLoading(false)
  }, [])

  return (
    <Layout title={t('documentTitle')} activePage="/contact/">
      <Map>
        <div>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.1887452800925!2d-73.7484673851024!3d45.586750333279774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9215dba2b3a9b%3A0x27ca6b99477407a9!2s1609%20Boulevard%20Saint-Elz%C3%A9ar%20O%2C%20Laval%2C%20QC%20H7L%205C5!5e0!3m2!1sen!2sca!4v1628134203887!5m2!1sen!2sca"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
          ></iframe>
        </div>
      </Map>
      <DescriptionContainer>
        <h1>Exclusive Maintenance</h1>
        <div className="desktop">
          <div>
            <h4>{t('hq')}</h4>
            <p>
              1609 boulevard Saint-Elzéar Ouest
              <br />
              Laval (Québec) H7L 3N6
              <br />
              {t('phone')}: <a href="tel:514-744-6991">514 744-6991</a>
              <br />
              <a href="tel:1-844-744-6991">1 844 744-6991</a>
              <br />
              {t('fax')}: <a href="tel:450-437-2388">450 437-2388</a>
            </p>
          </div>
          <div>
            <h4>{t('regionalOffice')}</h4>
            <p>
              15-245 boulevard Matheson Est
              <br />
              Mississauga (Ontario) L4Z 3C9
            </p>
          </div>
        </div>
      </DescriptionContainer>
      <WriteContainer>
        <div className="desktop">
          <div className="title">
            <h2>{t('contactUs')}</h2>
          </div>
          <span className="required">{t('required')}</span>
          <Form onSubmit={handleSubmit(submitForm)}>
            <FormProvider {...methods}>
              <FieldGroup>
                <Input name="firstName" label={t('firstName')} />
              </FieldGroup>
              <FieldGroup>
                <Input name="lastName" label={t('lastName')} />
              </FieldGroup>
              <FieldGroup>
                <Input type="email" name="email" label={t('email')} />
              </FieldGroup>
              <FieldGroup>
                <Input type="tel" name="phone" label={t('phoneLabel')} />
              </FieldGroup>
              <FieldGroup>
                <Input type="textarea" name="message" label={t('message')} />
              </FieldGroup>

              {success === undefined ? (
                <button className="submit" type="submit">
                  <span className={`button ${loading ? 'loading' : ''}`}>
                    <span className="underline">
                      {loading ? t('submitting') : t('send')}
                    </span>
                  </span>
                </button>
              ) : (
                <Feedback success={success}>
                  <Trans
                    i18nKey="contact:feedback"
                    values={{ context: String(success) }}
                  />
                </Feedback>
              )}
            </FormProvider>
          </Form>
        </div>
      </WriteContainer>
    </Layout>
  )
}

export default ContactPage

const Map = styled.section`
  ${({ theme }) => css`
    & > div {
      height: 25rem;
      ${theme.media.min('tablet')} {
        height: 28.125rem;
        width: calc(100% - (${theme.spacing.containerPadding} * 2));
        border-radius: 0 0 2.5rem 2.5rem;
        max-width: ${theme.spacing.maxWidth};
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
      }
    }
  `}
`

const DescriptionContainer = styled(Container)`
  ${({ theme }) => css`
    h4 {
      font-size: 1.26rem;
    }

    h1,
    h4 {
      color: ${theme.colors.brand};
    }

    ${theme.media.max('tablet')} {
      .desktop {
        div:nth-of-type(2) {
          margin-top: 1.5rem;
        }
      }
    }

    ${theme.media.min('tablet')} {
      .desktop {
        display: flex;
        justify-content: space-between;
      }
    }
  `}
`

const WriteContainer = styled(Container)`
  ${({ theme }) => css`
    color: ${theme.colors.white};

    ${theme.media.max('tablet_w')} {
      background: ${theme.colors.brand};
    }

    ${theme.media.min('tablet_w')} {
      padding-bottom: 5.125rem;

      .desktop {
        background: ${theme.colors.brand};
        border-radius: 2.5rem;
        padding: ${theme.spacing.containerPadding};
      }
    }

    .title {
      background: url(${messageWriting}) no-repeat left top;
      padding-left: 2.75rem;
      padding-bottom: 2rem;

      h2 {
        font-family: ${theme.typography.raleway};
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.33;
      }
    }
  `}
`

const FieldGroup = styled.div``

const Form = styled.form`
  ${({ theme }) => css`
    margin-top: 1rem;

    textarea {
      min-height: 9rem;
      font-family: inherit;
    }

    ${theme.media.min('tablet')} {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 1.25rem;

      ${FieldGroup} {
        &:nth-of-type(1) {
          grid-column: span 3;
        }
        &:nth-of-type(2) {
          grid-column: span 3;
        }
        &:nth-of-type(3) {
          grid-column: span 3;
        }
        &:nth-of-type(4) {
          grid-column: span 3;
        }
        &:nth-of-type(5) {
          grid-column: span 6;
        }
      }
      button {
        grid-column: span 6;

        &:hover {
          cursor: pointer;
        }
      }

      ${Feedback} {
        grid-column: span 6;
      }
    }

    .submit {
      background: none;
      border: none;
      width: 100%;
      margin-top: 3.75rem;

      .button {
        border: none;
        color: ${theme.colors.accent};
        font-family: ${theme.typography.montserrat};
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0.7rem 0 0.75rem 3.5rem;
        background: url(${mail}) no-repeat left top;
        background-position-y: 0.5rem;

        &.loading {
          opacity: 0.5;
        }
      }

      .underline {
        padding-bottom: 0.25rem;
        border-bottom: 2px solid ${theme.colors.accent};
      }
    }
  `}
`
